import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { image, logo, title } = content
  return (
    <Inview
      className={`${styles.homePanels} ${styles.hasPanels} ${styles.passive}`}
    >
      <div className={`${styles.electronic} ${styles.open}`}>
        <div className={styles.inner}>
          <div className={`${styles.imageBg} in in-fade-in`}>
            <ImageWrap image={image} />
          </div>
          <div className={`${styles.logo} in in-fade-in`}>
            <div className={styles.image}>
              <ImageWrap image={logo} />
            </div>
            <p>{title}</p>
          </div>
        </div>
      </div>
    </Inview>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment BannerHomeEn on MarkdownRemarkFrontmatterEnBlocks {
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
    logo {
      childImageSharp {
        gatsbyImageData
      }
    }
    title
  }
`
export const fragmentZh = graphql`
  fragment BannerHomeZh on MarkdownRemarkFrontmatterZhBlocks {
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
    logo {
      childImageSharp {
        gatsbyImageData
      }
    }
    title
  }
`
