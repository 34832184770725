import React, { useState } from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import LinkWrap from 'components/LinkWrap'
import ImageWrap from 'components/ImageWrap'
import { textAddSpans } from 'js/utils'
import Inview from 'components/Inview'
import { StaticImage } from 'gatsby-plugin-image'
import ModalVideo from 'components/ModalVideo'

const Block = ({ content, locale }) => {
  const { items, categories } = content
  const [activeCat, setActiveCat] = useState(categories[0].slug)

  return (
    <Inview className={styles.passive}>
      <Select
        content={{ categories }}
        activeCat={activeCat}
        setActiveCat={setActiveCat}
      />
      <section className={styles.products}>
        {items.map((item, i) => (
          <Item content={item} key={i} activeCat={activeCat} locale={locale} />
        ))}
      </section>
    </Inview>
  )
}

const Select = ({ content, activeCat, setActiveCat }) => {
  const { categories } = content
  return (
    <div className={styles.headerProduct}>
      <div className={styles.onglets}>
        <ul className='list-inline'>
          {categories.map((item, i) => {
            const { title } = item
            const titleSpan = textAddSpans(title)
            const activeClass = item.slug === activeCat ? styles.active : ''
            return (
              <li
                className={`list-inline-item ${activeClass} in in-fade-in-up-less stagger-${
                  i * 100
                }`}
                key={i}
                dangerouslySetInnerHTML={{ __html: titleSpan }}
                onClick={() => setActiveCat(item.slug)}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

const Item = ({ content, activeCat, locale }) => {
  const { slug, title, brief, productCategorySlugs } = content
  const titleSpan = textAddSpans(title)
  const activeClass = productCategorySlugs.includes(activeCat)
    ? styles.active
    : ''
  const riderefineClass = activeCat === 'riderefine' ? styles.riderefine : ''

  const strings = {
    en: {
      moreDetail: 'More Detail',
    },
    zh: {
      moreDetail: '更多详情',
    },
  }

  return (
    <article className={`${activeClass} ${riderefineClass}`}>
      <div className={styles.brandIntro}>
        {!brief.logo && (
          <h2
            className='in in-fade-in-up-100'
            dangerouslySetInnerHTML={{ __html: titleSpan }}
          />
        )}
        {brief.logo && (
          <div className={styles.logo}>
            <ImageWrap image={brief.logo} />
          </div>
        )}
        <div className={`${styles.body} in in-fade-in-up-100 stagger-100`}>
          <MarkdownWrap body={brief.body} />
        </div>
        {brief.videoSlug && (
          <VideoButton
            content={{ videoSlug: brief.videoSlug }}
            locale={locale}
          />
        )}
      </div>
      <div className={styles.brandSchema}>
        <div className={`${styles.image} in in-fade-in-up-100 stagger-200`}>
          <ImageWrap image={brief.image} />
        </div>
      </div>
      <div className={`${styles.brandLink} in in-fade-in stagger-500`}>
        <LinkWrap to={`/products/${slug}`}>
          {strings[locale].moreDetail}
          <i className='fal fa-chevron-right'></i>
        </LinkWrap>
      </div>
    </article>
  )
}

const VideoButton = ({ content, locale }) => {
  return (
    <div className={styles.videoButton}>
      <div className={styles.inner}>
        <StaticImage src='../../assets/images/video-button.png' alt='' />
        <ModalVideo content={content} locale={locale} />
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment ListDiagramItemsEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment ListDiagramItemsZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
