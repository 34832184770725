import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'

const Block = ({ content }) => {
  const { title, body, image } = content
  return (
    <div className={styles.component}>
      <div className={styles.headerProductsPassive}>
        <div className={styles.image}>
          <ImageWrap image={image} />
        </div>
        <div className={styles.headerContent}>
          <h1>{title}</h1>
          <MarkdownWrap body={body} />
        </div>
        <div className='onglets'>
          {/* <ul className='list-inline'>
          <li
            className='product-tab-item list-inline-item link-piston active'
            id='piston'
            style='opacity: 1; visibility: inherit; transform: translate(0px, 0px);'
          >
            Piston valving
          </li>
          <li
            className='product-tab-item list-inline-item link-riderefine'
            id='riderefine'
            style='opacity: 1; visibility: inherit; transform: translate(0px, 0px);'
          >
            Riderefine™ add-on valving
          </li>
        </ul> */}
        </div>
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TextImageEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    body
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export const fragmentZh = graphql`
  fragment TextImageZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    body
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
