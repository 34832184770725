// extracted by mini-css-extract-plugin
export var active = "styles-module--active--JuTjZ";
export var body = "styles-module--body--8t2ao";
export var brandIntro = "styles-module--brand-intro--+jKSM";
export var brandLink = "styles-module--brand-link--KNqYa";
export var brandSchema = "styles-module--brand-schema--Wufxp";
export var headerProduct = "styles-module--header-product--2nvQg";
export var image = "styles-module--image--4t5wj";
export var inner = "styles-module--inner--6Ft2r";
export var logo = "styles-module--logo--9KHPH";
export var onglets = "styles-module--onglets--UMVrE";
export var passive = "styles-module--passive--HGBeP";
export var products = "styles-module--products--fsp3Q";
export var riderefine = "styles-module--riderefine--2QU1r";
export var videoButton = "styles-module--video-button--hE7H+";